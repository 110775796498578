import React from "react";


const Footer: React.FC = () => {
    return (
        <div className="footer">
            <div className="footer_text">
                <p>Сервис Tvoi.dengi.ru бесплатно предоставляет информацию о займах. Процентные ставки и срок погашения займа могут отличаться, т. к. зависят от выбранной вами микрофинансовой организации (МФО). 
                    Ставки по займам могут составлять от 0% до 1% в день (максимальный процент по займу составит 365% годовых). 
                    Срок у данных займов может быть от 61 дня до 365 дней. Это означает, что минимальный срок погашения займа: 61 день. 
                    Максимальный срок погашения займа: 365 дней.<br/>
                    Пример расчета по займу на сумму 10 000 рублей сроком на 10 недель, т. е. на 70 дней, по ставке 0,75% в день — сумма процентов за весь период составит 5 250 рублей. Общая выплата будет равна 14 250 рублей.<br/>
                    При несоблюдении выплат в срок, предусмотренный в договоре, появляется штраф за просрочку займа. Проценты за пользование суммой займа при нарушении срока возврата займа и начисленных процентов на 10 дней (10% от суммы займа) — 1 000 рублей.
                </p>
            </div>
            <div className="footer_text">
                <p>Пеня на сумму займа в размере 20% годовых от суммы займа начисляется за просрочку возврата займа на 10 дней составит 51,7 рублей. 
                    Сумма к возврату при нарушении срока возврата займа и начисленных процентов на 10 дней: 14 250 + 1 000 + 51,7 = 15 301,7 рублей.<br/>
                    Информация о займах не является офертой или публичной офертой: конечные условия для выдачи конкретного займа определяются на сайте конкретного МФО в индивидуальном порядке.<br/>
                    Наши основные партнеры среди МФО: ООО МФК «Вэббанкир» (Рег. № 2120177002077), ООО МФК «Мани Мен» (Рег. № 2110177000478), ООО МФК «Займер» (Рег. № 651303532004088), ООО МКК «КапиталЪ-НТ» (Рег. № 001603465007766), ООО МКК «Русинтерфинанс» (Рег. № 2120754001243).
                </p>
            </div>
        </div>
    )
}

export default Footer;