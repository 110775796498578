import tinkoffLogo from '../assets/img/tinkoff.png';
import gaspromLogo from '../assets/img/gasprombank.png';
import creditClubLogo from '../assets/img/creditclub.png';
import carmoneyLogo from '../assets/img/carmoney.png';

import reneLogo from '../assets/img/renecredit.png';


export type CardRowType = {
    name: string,
    logo: string,
    sumMin: string,
    sumMax: string,
    percent: string,
    time: string,
    notification: string,
    link: string
}


export const dataTinkoffTable: CardTableType = {
    name: 'Тинькофф',
    logo: tinkoffLogo,
    title: 'Под залог автомобиля',
    sumTitle: 'СУММА',
    sum: '100 000 - 7 млн &#8381;',
    // sumMin: '100 000',
    // sumMax: '7 млн',
    percentTitle: 'СТАВКА',
    percent: 'от 7,9%',
    periodTitle: 'СРОК',
    period: '60 месяцев',
    age: '18 - 65',
    notification: 'Без справок и поручителей',
    link: 'https://pxl.leads.su/click/ad6e9ba41ba415e3476a649f600d886b?aff_sub2=ot&erid=LjN8KAhnE',
}

export const dataTinkoff: CardRowType = {
    name: 'Тинькофф',
    logo: tinkoffLogo,
    sumMin: '100 000',
    sumMax: '7 млн',
    percent: '7,9',
    time: '60 месяцев',
    notification: 'Без справок и поручителей',
    link: 'https://pxl.leads.su/click/ad6e9ba41ba415e3476a649f600d886b?aff_sub2=ot&erid=LjN8KAhnE',
}

export const dataGaspromTable: CardTableType = {
    name: 'Газпромбанк',
    logo: gaspromLogo,
    title: 'Под залог автомобиля',
    sumTitle: 'СУММА',
    sum: '500 000 - 7 млн &#8381;',
    // sumMin: '500 000',
    // sumMax: '7 млн',
    percentTitle: 'СТАВКА',
    percent: 'от 2,4%',
    periodTitle: 'СРОК',
    period: '60 месяцев',
    age: '18 - 65',
    notification: 'Самая низкая ставка',
    link: 'https://pxl.leads.su/click/2827305bc894c4f6e007742bddb6614a?aff_sub3=ot&erid=LjN8KP75M',
}

export const dataGasprom: CardRowType = {
    name: 'Газпромбанк',
    logo: gaspromLogo,
    sumMin: '500 000',
    sumMax: '7 млн',
    percent: '2,4',
    time: '60 месяцев',
    notification: 'Самая низкая ставка',
    link: 'https://pxl.leads.su/click/2827305bc894c4f6e007742bddb6614a?aff_sub3=ot&erid=LjN8KP75M',
}

export const dataCreditClubTable: CardTableType = {
    name: 'Credit.Club',
    logo: creditClubLogo,
    title: 'Под залог автомобиля',
    sumTitle: 'СУММА',
    sum: '100 000 - 500 000 &#8381;',
    percentTitle: 'СТАВКА',
    percent: 'от 9,5%',
    periodTitle: 'СРОК',
    period: '60 месяцев',
    age: '18 - 65',
    notification: 'ПТС остается у Вас',
    link: 'https://pxl.leads.su/click/8bf8c986a9f54053ee7d95e4fe493d93?aff_sub2=ot&erid=get_it_soon'
}

export const dataCreditClub: CardRowType = {
    name: 'Credit.Club',
    logo: creditClubLogo,
    sumMin: '100 000',
    sumMax: '500 000',
    percent: '9,5',
    time: '60 месяцев',
    notification: 'ПТС остается у Вас',
    link: 'https://pxl.leads.su/click/8bf8c986a9f54053ee7d95e4fe493d93?aff_sub2=ot&erid=get_it_soon'
}

export const dataCarMoneyTable: CardTableType = {
    name: 'Car Money',
    logo: carmoneyLogo,
    title: 'Под залог автомобиля',
    sumTitle: 'СУММА',
    sum: '80 000 - 1 млн &#8381;',
    percentTitle: 'СТАВКА',
    percent: 'от 2,83%',
    periodTitle: 'СРОК',
    period: '48 месяцев',
    age: '18 - 65',
    // sumMin: '80 000',
    // sumMax: '1 млн',
    // percent: '2,83',
    // time: '48 месяцев',
    notification: 'С любой кредитной историей',
    link: 'https://pxl.leads.su/click/d6e77b39889c416d597ede2fd55cba5d?aff_sub2=ot&erid=LjN8Jw1gG'
}

export const dataCarMoney: CardRowType = {
    name: 'Car Money',
    logo: carmoneyLogo,
    sumMin: '80 000',
    sumMax: '1 млн',
    percent: '2,83',
    time: '48 месяцев',
    notification: 'С любой кредитной историей',
    link: 'https://pxl.leads.su/click/d6e77b39889c416d597ede2fd55cba5d?aff_sub2=ot&erid=LjN8Jw1gG'
}

export type CardTableType = {
    name: string,
    logo: string,
    title: string,
    sumTitle: string,
    sum: string,
    percentTitle: string,
    percent: string,
    periodTitle: string,
    period: string,
    age: string,
    notification: string,
    link: string
}

export const renecredit: CardTableType = {
    name: 'Ренессанс Кредит',
    logo: reneLogo,
    title: 'Кредитная карта',
    sumTitle: 'СУММА',
    sum: 'до 600 000 &#8381;',
    percentTitle: 'СТАВКА',
    percent: '29,9%',
    periodTitle: 'ПЕРИОД БЕЗ %',
    period: 'до 140 дн',
    age: '18 - 65',
    notification: 'Бесплатное обслуживание!',
    link: 'https://pxl.leads.su/click/bacec22a21979b7f42ac132e85a87ae9?aff_sub2=ot&erid=LjN8KCSA1'
}

export const tinkoffPlatinum: CardTableType = {
    name: 'Тинькофф Платинум',
    logo: tinkoffLogo,
    title: 'Кредитная карта',
    sumTitle: 'СУММА',
    sum: 'до 700 000 &#8381;',
    percentTitle: 'СТАВКА',
    percent: '12,0%',
    periodTitle: 'ПЕРИОД БЕЗ %',
    period: 'до 55 дн',
    age: '18 - 65',
    notification: 'Доставка на дом',
    link: 'https://pxl.leads.su/click/c378b62af463bd745fb052acaba8336e?aff_sub2=ot&erid=LjN8KcE9k'
}

export const gaspromCredit: CardTableType = {
    name: 'Газпромбанк',
    logo: gaspromLogo,
    title: 'Кредит наличными',
    sumTitle: 'СУММА',
    sum: 'до 7 млн &#8381;',
    percentTitle: 'СТАВКА',
    percent: '4,4%',
    periodTitle: 'СРОК',
    period: 'до 60 мес',
    age: '18 - 65',
    notification: 'Быстрая выдача',
    link: 'https://pxl.leads.su/click/b68b56ddf1e3d2cf63dbdc5255123819?aff_sub2=ot&erid=LjN8Jy3FY '
}

export const tinkoffMagnit: CardTableType = {
    name: 'Тинькофф Магнит',
    logo: tinkoffLogo,
    title: 'Дебетовая карта',
    sumTitle: 'ОБСЛУЖИВАНИЕ',
    sum: '0 - 99 &#8381;/мес',
    percentTitle: 'ПОПОЛНЕНИЕ',
    percent: 'Бесплатно',
    periodTitle: 'КЭШБЕК',
    period: 'до 30%',
    age: '18 - 65',
    notification: 'Доставка на дом',
    link: 'https://pxl.leads.su/click/04f71cc1b3cf0aa26085d15b5ee684c8?aff_sub2=ot&erid=LjN8KK7jU'
}