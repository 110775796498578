import React from "react";
import CardRow from "./CardRowTemplate";
import './card.css';
import CardTable from "./CardTableTemplate";
import { dataCarMoney, dataCarMoneyTable, dataCreditClub, dataCreditClubTable, dataGasprom, dataGaspromTable, dataTinkoff, dataTinkoffTable, gaspromCredit, renecredit, tinkoffMagnit, tinkoffPlatinum } from "./data-cards";
//import { Row, Col } from 'antd';

const WrapperCards: React.FC = () => {
    return (
        <div className="wrapper_cards">
            <div className="wrapper_cards_row">
                <h3 className="text_title">Предложения специально для вас</h3>
                <div className="cards_row_cards">
                    <CardRow {...dataTinkoff}/>
                    <CardRow {...dataGasprom}/>
                    <CardRow {...dataCreditClub}/>
                    <CardRow {...dataCarMoney}/>
                </div>
                <div className="cards_table_cards" id="table_from_row">
                    <CardTable {...dataTinkoffTable}/>
                    <CardTable {...dataGaspromTable}/>
                    <CardTable {...dataCreditClubTable}/>
                    <CardTable {...dataCarMoneyTable}/>
                </div>
            </div>
            <div className="wrapper_cards_table">
                <h3 className="text_title">Другие предложения от банков</h3>
                <div className="cards_table_cards">
                    <CardTable {...renecredit}/>
                    <CardTable {...tinkoffPlatinum}/>
                    <CardTable {...gaspromCredit}/>
                    <CardTable {...tinkoffMagnit}/>
                </div>
            </div>
        </div>
    )
}

export default WrapperCards;