import React from "react";
import arrow from '../assets/img/arrow-right.png';
import './card.css';
//import bank from '../assets/img/tinkoff.png';
import { CardRowType } from "./data-cards";

const CardRow: React.FC<CardRowType> = (props) => {
    return (
        <div className="card_row_wrap">
            <div className="card_row_notification">
                <p>{props.notification}</p>
            </div>
            <div className="card_row">
                <div className="card_bank_row card_bank">
                    <div className="card_bank_img">
                        <img src={props.logo} alt="icon_bank"/>
                    </div>
                    <div className="card_bank_title">
                        <h5>{props.name}</h5>
                        <p>Под залог автомобиля</p>
                    </div>
                </div>
                <div className="card_description">
                    <div className="sum">
                        <h5>СУММА</h5>
                        <p>{props.sumMin} - {props.sumMax} &#8381;</p>
                    </div>
                    <div className="percent_container">
                        <div className="percent">
                            <h5>СТАВКА</h5>
                            <p>от {props.percent}%</p>
                        </div>
                    </div>
                    <div className="time">
                            <h5>СРОК</h5>
                            <p>до {props.time}</p>
                    </div>
                    
                </div>
                <a href={props.link} target='_blank' rel="noreferrer" className="card_button_link">
                    <div className="card_button_container card_button_container_row">
                        <div className="card_button">
                            <p>Отправить заявку</p>
                            <div className="card_button_arrow">
                                <img src={arrow} alt="arrow"/>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>

    )
}

export default CardRow;