import React from 'react';
import './header.css';
import logo from '../assets/icons/logo.png';
import location from '../assets/icons/location-dot.png';
import bolt from '../assets/icons/bolt.png';
import banner from '../assets/img/banner-group.png';
import banner768 from '../assets/img/banner-group-768.png';

const Header: React.FC = () => {

    return (
        <header>
            <div className='header__first-row'>
                <div className='header__first-row_container'>
                    <div className='header__logo'>
                        <img src={logo} alt='logo'/>
                    </div>
                    <div className='header__location_container'>
                        <div className='header__location'>
                            <img src={location} alt='location'/>
                        </div>
                        <p>Петропавловск-Камчатский</p>
                    </div>
                </div>
            </div>
            <div className='header__second-row_container'>
                <div className='header__second-row'>
                    <div className='header__second-row_text_container'>
                        <h1 className='text_title'>Подберем для вас кредит под залог автомобиля</h1>
                        <div className='header__second-row_text_table'>
                            <div className='header__second-row_text_table_element'>
                                <div className='header__second-row_text_table_element_bolt'>
                                    <img src={bolt} alt='icon'/>
                                </div>
                                <p>До 2 миллионов в течении 1 часа</p>
                            </div>
                            <div className='header__second-row_text_table_element'>
                                <div className='header__second-row_text_table_element_bolt'>
                                    <img src={bolt} alt='icon'/>
                                </div>
                                <p>Отправьте заявки сразу в несколько банков</p>
                            </div>
                            <div className='header__second-row_text_table_element'>
                                <div className='header__second-row_text_table_element_bolt'>
                                    <img src={bolt} alt='icon'/>
                                </div>
                                <p>Самые низкие процентные ставки на рынке</p>
                            </div>
                            <div className='header__second-row_text_table_element'>
                                <div className='header__second-row_text_table_element_bolt'>
                                    <img src={bolt} alt='icon'/>
                                </div>
                                <p>По 3 документам: паспорт, ПТС, СТС</p>
                            </div>
                        </div>
                    </div>
                    <div className='header__second-row_image'>
                        <img src={banner} alt='banner' id='banner1920'/>
                        <img src={banner768} alt='banner' id='banner768'/>
                        {/* <div className='header_circle'>
                        </div> */}
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;