import React from "react";
import arrow from '../assets/img/arrow-right.png';
import './card.css';
//import bank from '../assets/img/tinkoff.png';
import { CardTableType } from "./data-cards";

const CardTable: React.FC<CardTableType> = (props) => {
    return (
        <div className="card_table_wrap">
            <div className="card_table_notification">
                <p>{props.notification}</p>
            </div>
            <div className="card_table">
                <div className="card_bank card_bank_table">
                    <div className="card_bank_img">
                        <img src={props.logo} alt="icon_bank"/>
                    </div>
                    <div className="card_bank_title">
                        <h5>{props.name}</h5>
                    </div>
                    <div className="card_bank_p">
                        <p>{props.title}</p>
                    </div>
                </div>
                <div className="card_description_table">
                    <div className="sum">
                        <h5>{props.sumTitle}</h5>
                        <p dangerouslySetInnerHTML={{__html: props.sum}}></p>
                    </div>
                    <div className="percent">
                        <h5>{props.percentTitle}</h5>
                        <p>{props.percent}</p>
                    </div>
                    <div className="time">
                        <h5>{props.periodTitle}</h5>
                        <p>{props.period}</p>
                    </div>
                    <div className="age">
                        <h5>ВОЗРАСТ</h5>
                        <p>{props.age}</p>
                    </div>
                </div>
                <a href={props.link} target='_blank' rel="noreferrer" className="card_button_link">
                    <div className="card_button_container card_button_container_table">
                        <div className="card_button">
                            <p>Отправить заявку</p>
                            <div className="card_button_arrow">
                                <img src={arrow} alt="arrow"/>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>

    )
}

export default CardTable;